import { Box, Button, Grid, List, ListItem, Typography, useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import Image from "next/image";

import teams from "@public/images/lp/icons/teams.svg";
import inIcon from "@public/images/lp/icons/in.svg";
import slack from "@public/images/lp/icons/slack.svg";
import figma from "@public/images/lp/icons/figma.svg";

import { Link as ScrollLink } from "react-scroll";

const social = [figma, slack, inIcon, teams];

const Team = () => {
  const isMobile = useMediaQuery("(max-width:640px)");
  const isDesktop = useMediaQuery("(min-width:1200px)");

  return (
    <Box
      sx={{
        maxWidth: "1920px",
        margin: "0 auto"
      }}
    >
      <Grid
        container
        sx={{
          m: 0,
          padding: {
            lg: "150px 100px",
            md: "100px 40px",
            xs: "80px 24px",
            justifyContent: "space-between",
            flexWrap: "wrap"
          }
        }}
      >
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              textAlign: { sm: "left", xs: "center" },
              color: "primary.main",
              fontSize: { md: "42px", sm: "24px", xs: "16px" },
              fontWeight: 500,
              lineHeight: "43px",
              letterSpacing: { md: "1.26px", xs: "0.48px" },
              textTransform: "capitalize"
            }}
          >
            We believe in our relationship
          </Typography>

          <Card
            sx={{
              mt: "20px",
              borderRadius: "16px",
              border: "1px solid #1E54F5",
              padding: { sm: "41px 74px 41px 26px", xs: "20px" },
              textAlign: { sm: "left", xs: "center" }
            }}
          >
            <Typography
              sx={{
                maxWidth: "700px",
                color: "text.greyLight",
                fontSize: { md: "18px", xs: "14px" },
                fontWeight: 500,
                lineHeight: { md: "43px", xs: "24px" },
                letterSpacing: { md: "0.54px", xs: "0.42px" }
              }}
            >
              At Vigorant, we don&apos;t just offer a tool—we strive to be your trusted partner in building lasting
              relationships with your audience. Reach out to us and unlock the full potential of your business strategy!
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: { sm: "start", xs: "center" },
                justifyContent: "space-between",
                flexDirection: { sm: "row", xs: "column" },
                flexWrap: "wrap",
                mt: "28px"
              }}
            >
              <Button
                variant='outlined'
                sx={{
                  whiteSpace: "nowrap",
                  textTransform: "none",
                  borderRadius: "12px",
                  width: { md: "230px", sm: "50%" },
                  height: { sm: "70px", xs: "52px" },
                  letterSpacing: "0.72px",
                  fontSize: { md: "24px", sx: "14px" },
                  fontWeight: 700,
                  mr: { sm: "20px", xs: 0 },
                  mb: "20px"
                }}
              >
                <ScrollLink to='contact' smooth={true} duration={1000} offset={-100}>
                  Get in Touch
                </ScrollLink>
              </Button>

              <List sx={{ display: "flex", justifyContent: "flex-start", width: "50%" }}>
                {social.map((item, index) => (
                  <ListItem sx={{ p: 0, mr: "40px" }} key={index}>
                    <Image src={item} alt='icon' width={isMobile ? 28 : 54} height={isMobile ? 28 : 54} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>

        {isDesktop && (
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              mt: { lg: 0, sm: "60px", xs: "40px" },
              ml: { md: "0", lg: "62px" },
              borderRadius: "8px",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <Box
              sx={{
                position: "relative",
                borderRadius: "8px",
                width: "100%",
                height: "400px",
                backgroundImage: `linear-gradient(rgba(34, 34, 34, 0.53), rgba(34, 34, 34, 0.53)), url('/images/lp/Team.png')`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            ></Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Team;
