import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Image from "next/image";

const Slide = ({ title, subtitle, text, image }) => {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        color: "white",
        padding: {
          lg: "137px 100px",
          md: "80px 60px",
          xs: "60px 24px"
        }
      }}
    >
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid item lg={5} xs={12} sx={{ mb: "20px" }}>
          <Typography
            sx={{
              fontSize: { lg: "42px", sm: "28px", xs: "18px" },
              fontWeight: 700,
              letterSpacing: "0.03em",
              lineHeight: { lg: "77px", xs: "60px" }
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: { lg: "24px", sm: "20px", xs: "18px" },
              fontWeight: 500,
              lineHeight: { sm: "40px", xs: "24px" },
              letterSpacing: "3%",
              mb: "25px"
            }}
          >
            {subtitle}
          </Typography>

          <Typography
            sx={{
              fontSize: { lg: "18px", sm: "16px", xs: "14px" },
              fontWeight: 500,
              lineHeight: { sm: "40px", xs: "24px" },
              letterSpacing: "3%"
            }}
          >
            {text}
          </Typography>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Box sx={{ display: "flex" }}>
            <Image
              src={image}
              alt=''
              layout='responsive'
              width={812}
              height={468}
              sx={{ maxWidth: "100%", height: "auto" }}
            />

            {isDesktop && (
              <Box sx={{ ml: "40px" }}>
                <svg width='66' height='62' viewBox='0 0 66 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M33.4686 61.9415C26.3986 61.9336 19.6207 59.1208 14.6224 54.1207C9.62409 49.1205 6.81389 42.3415 6.80859 35.2715H18.7386C18.7386 39.1781 20.2905 42.9248 23.0529 45.6872C25.8153 48.4496 29.5619 50.0015 33.4686 50.0015C37.3752 50.0015 41.1219 48.4496 43.8843 45.6872C46.6467 42.9248 48.1986 39.1781 48.1986 35.2715H60.1286C60.1233 42.3415 57.3131 49.1205 52.3148 54.1207C47.3165 59.1208 40.5385 61.9336 33.4686 61.9415Z'
                    fill='white'
                    fill-opacity='0.2'
                  />
                  <path
                    d='M27.5 52.5C21.269 52.4947 15.2948 50.0171 10.8889 45.6112C6.4829 41.2052 4.0053 35.231 4 29H9.60999C9.60999 33.7447 11.4948 38.2951 14.8499 41.6501C18.2049 45.0052 22.7553 46.89 27.5 46.89C32.2447 46.89 36.7951 45.0052 40.1501 41.6501C43.5052 38.2951 45.39 33.7447 45.39 29H51.01C51.0021 35.2319 48.5222 41.2061 44.1147 45.6118C39.7071 50.0175 33.7319 52.4947 27.5 52.5Z'
                    fill='white'
                  />
                  <path
                    d='M0 31.353C0.330043 22.9265 3.90958 14.955 9.98811 9.10992C16.0666 3.26481 24.1721 0 32.605 0C41.0379 0 49.1434 3.26481 55.2219 9.10992C61.3004 14.955 64.88 22.9265 65.21 31.353L0 31.353Z'
                    fill='white'
                    fill-opacity='0.2'
                  />
                  <path
                    d='M13.7988 37.3228C13.9931 32.4549 16.0635 27.8509 19.5764 24.4755C23.0892 21.1 27.772 19.2148 32.6438 19.2148C37.5156 19.2148 42.1984 21.1 45.7113 24.4755C49.2241 27.8509 51.2946 32.4549 51.4888 37.3228H13.7988Z'
                    fill='#FFB624'
                  />
                </svg>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Slide;
