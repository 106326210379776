import { Box, Button, Menu, MenuItem, IconButton, useMediaQuery, ListItem, List, Avatar } from "@mui/material";
import MenuIcon from "@components/lp/common/icons/menu";
import Image from "next/image";
import logo from "@public/images/lp/logo.png";
import logoMobile from "@public/images/lp/LogoMobile.svg";
import Link from "next/link";
import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const navigation = [
  {
    title: "Features",
    anchor: "features"
  },
  {
    title: "Product Guide",
    anchor: "demo"
  },
  {
    title: "Testimonials",
    anchor: "testimonials"
  },
  {
    title: "Contact us",
    anchor: "contact"
  }
];

const Header = () => {
  const [activeTab, setActiveTab] = useState("Features");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery("(max-width:920px)");

  const handleMenuOpen = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box sx={{ background: "background.white", boxShadow: "0px 6px 32.1px 0px rgba(46, 106, 219, 0.28)" }}>
      <Box
        sx={{
          maxWidth: "1920px",
          margin: "0 auto",
          display: "flex",
          padding: { lg: "0px 100px", xs: "0px 19px" },
          alignItems: "center",
          justifyContent: "space-between",
          height: "120px"
        }}
      >
        {!isMobile && <Image src={logo} alt='logo' height={46} width={219} />}

        {isMobile && (
          <IconButton onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
        )}

        {isMobile && <Image src={logoMobile} alt='logo' />}

        {isMobile && <Avatar alt='user' />}

        <Menu
          sx={{
            "& .MuiPaper-root.MuiPopover-paper": {
              width: "242px",
              left: "auto!important",
              top: "120px!important",
              borderRadius: "0px 15px 15px 0px"
            }
          }}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #2E6ADB",
              padding: "25px",
              "&:hover": { backgroundColor: "#fff" },
              justifyContent: "space-between"
            }}
          >
            <Image src={logoMobile} alt='logo' />

            <IconButton sx={{ color: "primary.main" }} onClick={handleMenuClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>

          {navigation.map((item, index) => (
            <MenuItem
              key={index}
              onClick={handleMenuClose}
              sx={{
                cursor: "pointer",
                fontSize: { sm: "16px", xs: "12px" },
                fontWeight: 500,
                color: "text.greyLight",
                letterSpacing: "0.36px",
                padding: "12px 20px",
                "&:hover": { color: "text.primary", fontWeight: 700 }
              }}
            >
              <ScrollLink to={item.anchor} smooth={true} duration={1000} offset={-100}>
                {item.title}
              </ScrollLink>
            </MenuItem>
          ))}
          <MenuItem onClick={handleMenuClose}>
            <Button
              href='/'
              component={Link}
              variant='contained'
              sx={{
                whiteSpace: "nowrap",
                textTransform: "none",
                borderRadius: "8px",
                height: { sm: "54px", xs: "37px" },
                width: "auto",
                letterSpacing: { sm: "0.54px", xs: "0.36px" },
                fontSize: { sm: "16px", xs: "12px" },
                marginTop: "10px",
                fontWeight: 700
              }}
            >
              Sign in
            </Button>
          </MenuItem>
        </Menu>

        {!isMobile && (
          <>
            <List
              sx={{
                display: "flex",
                whiteSpace: "nowrap",
                fontSize: { lg: "24px", xs: "20px" },
                fontWeight: "700",
                lineHeight: "normal",
                letterSpacing: "0.72px"
              }}
            >
              {navigation.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { color: "text.primary" },
                    color: "text.greyLight"
                  }}
                >
                  <ScrollLink to={item.anchor} smooth={true} duration={1000} offset={-100}>
                    {item.title}
                  </ScrollLink>
                </ListItem>
              ))}
            </List>

            <Button
              href='/login'
              component={Link}
              variant='contained'
              sx={{
                whiteSpace: "nowrap",
                textTransform: "none",
                borderRadius: "8px",
                height: "59px",
                minWidth: "117px",
                letterSpacing: "0.54px",
                fontSize: "18px",
                fontWeight: 700
              }}
            >
              Sign in
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Header;
