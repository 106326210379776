import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import CustomTitle from "@components/lp/common/CustomTitle";
import Link from "next/link";
import Image from "next/image";
import Overview from "@public/images/lp/Overview.png";
import OverviewMobile from "@public/images/lp/OverviewMobile.png";

export const Hero = () => {
  const isDesktop = useMediaQuery("(min-width:1200px)");
  const isMobile = useMediaQuery("(max-width:900px)");

  return (
    <Box sx={{ backgroundColor: "background.grayLight" }}>
      <Grid
        container
        sx={{
          maxWidth: "1920px",
          margin: "0 auto",
          justifyContent: "space-between",
          padding: {
            lg: "137px 0 137px 100px",
            md: "80px 0 80px 60px",
            xs: "60px 24px"
          }
        }}
      >
        <Grid item lg={4} xs={10} sx={{ pr: { lg: "0", md: "60px", xs: "24px" } }}>
          <Box
            sx={{
              width: { xs: "100%", sm: "auto" },
              maxWidth: { xs: "250px", sm: "100%" },
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #3067DB",
              color: "primary.main",
              whiteSpace: "nowrap",
              textTransform: "none",
              borderRadius: "36px",
              padding: { md: "0 16px", sm: "0 6px" },
              height: "49px",
              letterSpacing: "0.54px",
              fontSize: { md: "16px", sm: "14px", xs: "8px" },
              lineHeight: "76px",
              fontWeight: 700
            }}
          >
            <span style={{ marginRight: isMobile ? "5px" : "11px" }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={isMobile ? "8" : "12"}
                height={isMobile ? "8" : "13"}
                viewBox='0 0 12 13'
                fill='none'
              >
                <circle cx='6' cy='6.5' r='6' fill='#2E6ADB' />
              </svg>
            </span>
            Build A More Reputable Online Presence
          </Box>

          <CustomTitle
            sx={{
              textAlign: "left",
              fontSize: { md: "48px", sm: "36px", xs: "20px" },
              fontWeight: "700",
              lineHeight: { md: "76px", sm: "56px", xs: "24px" },
              mt: { md: "39px", xs: "24px" }
            }}
            text='Transform Your Business with Insightful Analytics for'
            colorText='Effortless Management'
          />

          <Typography
            sx={{
              fontWeight: 500,
              color: "text.greyLight",
              mt: { md: "52px", xs: "22px" },
              mb: { md: "62px", xs: "22px" },
              fontSize: { md: "24px", xs: "14px" },
              letterSpacing: { md: "0.72px", xs: "0.42px" },
              lineHeight: { md: "43px", xs: "24px" }
            }}
          >
            Take control of your daily business performance and harness the power of data-driven decision-making with
            our intuitive My.Vigorant Dashboard. It consolidates all your performance data into a clear, structured
            overview, providing a comprehensive view of your marketing campaigns and operations. This holistic
            perspective empowers you to refine your strategies and maximize your returns on investment.
          </Typography>

          {isDesktop && (
            <Button
              href={`mailto:leads@vigorant.com`}
              component={Link}
              variant='contained'
              sx={{
                width: "100%",
                borderRadius: "15px",
                letterSpacing: { sm: "0.72px", xs: "0.48pxx" },
                height: { sm: "70px", xs: "36px" },
                maxWidth: { sm: "405px", xs: "100%" },
                fontSize: { md: "24px", xs: "16px" },
                lineHeight: "24px",
                fontWeight: 700,
                marginBottom: "50px",
                textTransform: "unset"
              }}
            >
              Get it Free
            </Button>
          )}
        </Grid>

        <Grid item lg={7} xs={12}>
          <Box
            sx={{
              boxShadow: { md: "0", xs: "0px 6px 19px 0px #00000040" },
              ml: { md: "-18px", xs: 0 },
              borderRadius: "5px"
            }}
          >
            <Image
              src={!isMobile ? Overview : OverviewMobile}
              alt='overview'
              layout='responsive'
              width={1200}
              height={585}
              sx={{ maxWidth: "100%", height: "auto", borderRadius: "5px" }}
            />
          </Box>

          {!isDesktop && (
            <Button
              href={`mailto:leads@vigorant.com`}
              component={Link}
              variant='contained'
              sx={{
                mt: { sm: "40px", xs: "22px" },
                borderRadius: "8px",
                letterSpacing: { sm: "0.72px", xs: "0.48pxx" },
                height: { md: "70px", sm: "50px", xs: "36px" },
                width: { md: "50%", xs: "100%" },
                fontSize: { md: "24px", xs: "16px" },
                lineHeight: "24px",
                fontWeight: 700,
                textTransform: "unset"
              }}
            >
              Get it Free
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
