import { Box } from "@mui/material";
import Slide from "@components/lp/common/Slide";
import Slider from "react-slick";
import { seo } from "@constants/lp/seo";

export const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "0px",
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  adaptiveHeight: true
};

const SeoBanner = () => {
  return (
    <Box
      sx={{
        height: "100%",
        background: "linear-gradient(90.73deg, #1E54F5 -11.16%, #B111CB 144.89%)"
      }}
      id='features'
    >
      <Slider {...settings} className='custom-slider'>
        {seo.map((item, index) => (
          <Box key={index}>
            <Slide image={item.image} text={item.text} title={item.title} subtitle={item.subtitle} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SeoBanner;
