import Card from "@mui/material/Card";
import { Avatar, Box, Grid, Rating, Typography, useMediaQuery } from "@mui/material";

const TestimonialCard = ({ author, photo, position, testimonial, rating }) => {
  const [firstName, lastName] = author.split(" ");
  const firstLetter = firstName ? firstName[0] : "";
  const lastLetter = lastName ? lastName[0] : "";

  return (
    <Card
      sx={{
        boxShadow: "0px 1px 49.7px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: "20px",
        p: "25px",
        height: "100%",
        maxWidth: { md: "642px", sm: "400px", xs: "360px" }
      }}
    >
      <Grid container spacing={2.5} sx={{ flexDirection: "column" }}>
        <Grid item>
          <Box sx={{ display: "flex" }}>
            <Avatar
              src={photo}
              alt={author}
              sx={{
                width: { md: "100px", xs: "60px" },
                height: { md: "100px", xs: "60px" },
                fontSize: { md: "42px", xs: "14px" }
              }}
            >
              {firstLetter}
              {lastLetter}
            </Avatar>

            <Box sx={{ ml: "17px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "23.4px"
                }}
              >
                {author}
              </Typography>
              <Typography
                sx={{
                  mt: { md: "9px", xs: 0 },
                  color: "text.greyDark",
                  fontSize: { md: "20px", xs: "16px" },
                  fontWeight: 450,
                  lineHeight: "26px"
                }}
              >
                {position}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Rating value={rating} readOnly />
        </Grid>

        <Grid item>
          <Typography
            sx={{
              color: "text.greyLight",
              fontSize: { md: "18px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "32px",
              letterSpacing: { md: "0.54px", xs: "0.3px" }
            }}
          >
            {testimonial}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TestimonialCard;
